//After beta test redo navigation from scratch with new logic
import React, { useState } from "react";
import { Nav, NavContainer, NavLogoText, WebLinks } from "./styles/Nav.styled";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { download } from "../../../util/downloader";

const NavBar = () => {
  //State for the navigation to be seen on scroll default to top (top of the screen)
  const [hState, sethState] = useState("top");
  //For the navbar to disappear and reappear on scroll down and reappear on scoll up
  //Last val is the initial scroll value
  //onScroll use call the function below
  useEffect(() => {
    var lastVal = 0;
    window.onscroll = function () {
      //The current value of the scroll position
      let y = window.scrollY;
      if (y > lastVal) {
        sethState("down");
      }
      if (y < lastVal) {
        sethState("up");
      }
      //if the scroll bar is at the top of the screen
      if (y === 0) {
        sethState("top");
      }
      lastVal = y;
    };
  }, []);

  const handleDownloadClick = (e) => {
    e.preventDefault();
    if (window.innerWidth >= 768) {
      download();
    } else {
      alert("Please download on your computer");
    }
  };

  return (
    <NavContainer
      className={hState}
      initial={{ opacity: 0, y: -80 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ ease: "easeInOut", duration: 2.4, delay: 2.5 }}
    >
      <Nav>
        <Link to={"/"} style={{ textDecoration: "none", flexGrow: "1" }}>
          <NavLogoText>Spingle.ai</NavLogoText>
        </Link>
        <WebLinks
          href="https://spingle-ai.typeform.com/to/gSMC3vYX?typeform-source=spingle.ai"
          rel="noopener noreferrer"
          target="_blank"
        >
          Start Free Trial
        </WebLinks>
      </Nav>
    </NavContainer>
  );
};

export default NavBar;
