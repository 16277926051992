import React from "react";
import {
  PreFooterContainer,
  PreFooterParagraph,
  PreFooterTextContainer,
} from "./styles/PreFooter.style";
import GradientHeading from "../../SharedComponents/GradientHeading/GradientHeading";

const PreFooter = () => {
  return (
    <PreFooterContainer>
      <GradientHeading
        text={
          "Start closer to the finish line with customizable templates by industry experts"
        }
        ta={"center"}
      />

      <PreFooterTextContainer>
        <PreFooterParagraph>
          Spingle AI can train on past projects to create your own Custom AI
          Templates for any style you want.
        </PreFooterParagraph>
        <PreFooterParagraph>Yes you read that correctly.</PreFooterParagraph>
        <PreFooterParagraph>
          Coming Soon... Sign up for early access{" "}
          <a href="https://spingle-ai.typeform.com/to/gSMC3vYX?typeform-source=localhost" target="_blank" style={{color:'#dccfff'}}>
            here.
          </a>
        </PreFooterParagraph>
      </PreFooterTextContainer>
    </PreFooterContainer>
  );
}; 

export default PreFooter;
