//When the trailer is released we can uncomment the code and link the vimeo file to the trailer
//Add Second call to action button for signup when mobile

import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { media } from "../../../style/media";
import {
  StyledImage,
  CTAParagraph,
  StyledIFrame,
  GradientLandingHeading,
  MediaContainer,
  LandingHeading,
  TextContainer,
} from "./styles/CTA.styled";
import Button from "../../SharedComponents/Button";
import { motion } from "framer-motion";
import { download } from "../../../util/downloader";
//CTA Containter Div
const CTA = styled(motion.div)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    -45deg,
    #440e86,
    #4d1592,
    #09080a,
    #431f6f,
    #631409
  );
  animation: gradient 20s ease infinite;
  background-size: 400% 400%;
  overflow: hidden;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 5rem;
  border-radius: 3% 3% 10% 10% / 2% 2% 0% 0%;

  @media ${media.laptop} {
    border-radius: 0;
  }

  @media ${media.tablet} {
    justify-content: center;
    padding-top: 4rem;
  }
`;

const CallToAction = () => {
  // Checks if the iframe is in view to see if the video should play
  const videoRef = useRef(null);
  const [isInViewport, setIsInViewport] = useState(false);

  // Use the IntersectionObserver WebAPI to observe the video player element
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Play when 50% of the video is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // Check if the video player is in the viewport
        if (entry.isIntersecting) {
          setIsInViewport(true);
        } else {
          setIsInViewport(false);
        }
      });
    }, options);

    // Observe the video player element
    observer.observe(videoRef.current);

    // Clean up the observer when the component is unmounted
    return () => observer.disconnect();
  }, []);

  // Function to handle player state changes
  const handlePlayerStateChange = (event) => {
    const playerState = event.data;
    if (playerState === 1) {
      // Playing
      console.log("Video is playing");
    } else if (playerState === 2) {
      // Paused
      console.log("Video is paused");
    }
  };

  const handleDownloadClick = (e) => {
    e.preventDefault();
    if (window.innerWidth >= 768) {
      download();
    } else {
      alert("Please download on your computer");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#0A0A0A",
      }}
    >
      <CTA
        animate={{
          scale: [2, 2, 2, 1, 1],
        }}
        transition={{
          duration: 2.3,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 1],
          delay: 2.5,
        }}
      >
        <TextContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 4.5 }}
        >
          <LandingHeading
            variants={{
              visible: {
                opacity: 1,
                x: 0,
                transition: {
                  type: "spring",
                  damping: 12,
                  stiffness: 100,
                },
              },
              hidden: {
                opacity: 0,
                x: 20,
                transition: {
                  type: "spring",
                  damping: 12,
                  stiffness: 100,
                },
              },
            }}
          >
            Instant Video Edits
          </LandingHeading>
          <GradientLandingHeading>
            <em>Your Direction</em>
          </GradientLandingHeading>
          <CTAParagraph>
            Spingle AI is a plugin that integrates with existing video editing
            softwares. It sifts out low-quality footage like blurry or shaky
            clips, selects the optimal shots, and auto-assembles them into
            sequences, ready for your final touch-ups.
          </CTAParagraph>
          <Button
            text={"Start Free Trial"}
            colour={"#0F0F0F"}
            padding={"1rem 3.5rem"}
            href="https://spingle-ai.typeform.com/to/gSMC3vYX?typeform-source=spingle.ai"
          />
        </TextContainer>
        {/* <DraggableSlider /> */}

        <MediaContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 4.7 }}
        >
          {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/8CjH7ZvuVVU?si=6od1jRwix08pvBvd&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
          <StyledImage
            src="https://res.cloudinary.com/dd0g0joex/image/upload/v1690206662/new_spingle_vybss0.png"
            alt="Spingle AI Logo"
          />
          <StyledIFrame
            // Updated video URL with enablejsapi=1 to enable the YouTube iframe API
            src="https://www.youtube.com/embed/V8XZU3ZMRIA?si=nzzWnseqXego8u3V&amp;controls=1&mute=1&autoplay=1&loop=1&list=PLnhYo9g4_TktK4BDwZECU3RZvWw6vKYg6"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            ref={videoRef}
            // Add the onLoad event handler to the iframe to set up the player events
            onLoad={() => {
              const player = new window.YT.Player(videoRef.current, {
                events: {
                  onStateChange: handlePlayerStateChange,
                },
              });
            }}
          />
        </MediaContainer>
      </CTA>
    </div>
  );
};

export default CallToAction;
