import React from 'react'
import { HeadingText } from './style/GradientHeading.style'

const GradientHeading = ({text, mt, mb, ta}) => {
  return (
    <HeadingText mt={mt} mb={mb} ta={ta}>
        {text}
    </HeadingText>
  )
}

export default GradientHeading